( function( ) {
	Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('#_token').getAttribute('value');
	Vue.component('eliminar-registro', {
		props: ['elid'],
		created: function() {},
		methods: {
			deleteItem: function(id, url) {
				var vm = this;
				swal({
					title: "Estás Seguro?",
					text: "Favor de confirmar que desea eliminar el registro. El registro no podrá ser recuperado posteriormente.",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#DD6B55",
					confirmButtonText: "Si, eliminar!",
					closeOnConfirm: false
				},
				function(){
					vm.$http.post(url, {
						id: id,
					})
					.then(function(response) {
						if(response.data == 1) {
							swal({
								title: "Eliminado!",
								text: "El registro se ha eliminado del sistema",
								timer: 2000,
								type: "success",
								showConfirmButton: false
							});
							setTimeout(location.reload.bind(location), 2000);
						} else {
							swal('Aviso', 'No se ha podido eliminar el registro', 'info');
						}
					});
				});
				return false;
			}
		},
	});
	Vue.component('tour-image', {
		props: ['id'],
		created: function() {},
		methods: {
			deletePicture: function(id) {
				console.log(id);
				this.$http.post('/api/tour/delete-gallery-foto', { id: id, _method: 'DELETE' })
				.then(function(response) {
					if(response.data == 2) {
						swal('Aviso', 'No se ha podido eliminar la imágen', 'error');
					} else {
						swal({
							title: "Bien!",
							text: "Se ha eliminado la imágen",
							timer: 2000,
							showConfirmButton: false,
							type: 'success'
						});
						setTimeout(location.reload.bind(location), 2000);
					}
				}, function(response) {
					swal('Aviso', 'No se ha podido eliminar la imágen', 'error');
				});
			},
		},
	});
	Vue.component('listado-tarifas', {
		props: ['clase'],
		data: function() {
			return {
				listadoTarifas: {},
			}
		},
		created: function() {
			this.getTarifas(this.clase);
		},
		methods: {
			getTarifas: function(clase) {
				this.$http.post('/api/get-tarifas', { clase: clase })
				.then(function(response) {
					this.listadoTarifas = response.data;
				});
			},
		}
	});
	Vue.component('ver-tarifa', {
		props: ['elid'],
		data: function() {
			return {
				urlref: '',
			}
		},
		created: function() {
			this.urlref = '/admin/tarifas/' + this.elid;
		}
	});
	Vue.component('editar-tarifa', {
		props: ['elid'],
		data: function() {
			return {
				urlref: '',
			}
		},
		created: function() {
			this.urlref = '/admin/tarifas/' + this.elid + '/edit';
		}
	});
	new Vue({
		el: '#AdminArea',
	});
}) ( );
