( function( ) {
	var controller = new slidebars();
	controller.init();
	var sidebar = $( '#toggle-Menu--Left' );
	// controller.open('Menu--Left');
	sidebar.on( 'click', function ( event ) {
		event.stopPropagation();
		event.preventDefault();
		controller.toggle( 'Menu--Left' );
	} );
	var nolinks = $('a.no-links');
	nolinks.each(function(e) {
		$(this).on('click', function(e) {
			e.preventDefault();
		});
	})
}) ( );
